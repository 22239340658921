import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
  PopoverBody,
  PopoverArrow,
  PopoverContent,
  Popover,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode, useRef } from 'react';
import {
  // FiDownload,
  FiImage,
  FiLink,
  FiMaximize2,
  FiMoreVertical,
} from 'react-icons/fi';
import styles from './plot-card.module.css';
import { DownloadPlotPNG } from './download-plot-png';
import { PlotLinkModal } from '@revelio/composed';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { D3ChartNames, ID3ChartProps } from '@revelio/d3';
import { PlotData } from '@revelio/layout';

type MenuOptions = 'expand' | 'generate-link' | 'download-png';
// | 'download-data';

export type LegacyChartProps = {
  type: D3ChartNames;
  props: ID3ChartProps;
  data: PlotData;
};

type PlotCardProps = {
  title: string;
  info?: ReactNode;
  topRight?: ReactNode;
  menu?: MenuOptions[];
  legacyChartProps?: LegacyChartProps;
  children: ReactNode;
};

export const PlotCard = ({
  title,
  info,
  topRight,
  menu,
  legacyChartProps,
  children,
}: PlotCardProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: openModal,
    onClose: closeModal,
  } = useDisclosure();

  const printRef = useRef<HTMLDivElement>(null);
  const {
    isOpen: isDownloadPNGModalOpen,
    onOpen: openDownloadPNGModal,
    onClose: closeDownloadPNGModal,
  } = useDisclosure();

  const {
    isOpen: isPlotLinkModalOpen,
    onOpen: openPlotLinkModal,
    onClose: closePlotLinkModal,
  } = useDisclosure();

  return (
    <>
      <Card className={styles.card} variant="unstyled" ref={printRef}>
        <CardHeader className={styles.cardHeader}>
          <Heading size="xs" color="text.primary" fontWeight="semibold">
            <div className={styles.titleContainer}>
              <div className={styles.title}>{title}</div>
              {info && (
                <div className={styles.info}>
                  <Popover trigger="hover" placement="top" variant="tooltip">
                    <PopoverTrigger>
                      <InfoOutlineIcon boxSize={'10px'} />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverBody>{info}</PopoverBody>
                    </PopoverContent>
                  </Popover>
                </div>
              )}
            </div>
          </Heading>
          <div id="plot-card-top-right" className={styles.topRight}>
            {topRight}
            {menu && menu?.length > 0 && (
              <Menu placement="bottom-end">
                <MenuButton
                  as={IconButton}
                  size="xs"
                  marginLeft="4px"
                  width="16px"
                  minWidth="16px"
                  variant="ghost"
                  colorScheme="gray"
                  icon={<FiMoreVertical />}
                  className={styles.menuButton}
                />
                <MenuList className={styles.menuList} minWidth="10rem">
                  {menu.includes('expand') && (
                    <MenuItem
                      className={styles.menuItem}
                      icon={<FiMaximize2 />}
                      onClick={openModal}
                    >
                      Expand Plot
                    </MenuItem>
                  )}
                  {menu.includes('generate-link') && (
                    <MenuItem
                      className={styles.menuItem}
                      icon={<FiLink />}
                      onClick={openPlotLinkModal}
                    >
                      Plot Link
                    </MenuItem>
                  )}
                  {menu.includes('download-png') && (
                    <MenuItem
                      className={styles.menuItem}
                      icon={<FiImage />}
                      onClick={openDownloadPNGModal}
                    >
                      Download PNG
                    </MenuItem>
                  )}
                  {/* TODO: Add download data functionality */}
                  {/* {menu.includes('download-data') && (
                    <MenuItem className={styles.menuItem} icon={<FiDownload />}>
                      Download Data
                    </MenuItem>
                  )} */}
                </MenuList>
              </Menu>
            )}
          </div>
        </CardHeader>
        <CardBody className={styles.cardBody} padding="18px 0 0">
          {children}
        </CardBody>
      </Card>

      <Modal isOpen={isModalOpen} onClose={closeModal} size={'6xl'}>
        <ModalOverlay />
        <ModalContent height={'80%'}>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>

      <DownloadPlotPNG
        isOpen={isDownloadPNGModalOpen}
        onClose={closeDownloadPNGModal}
        title={title}
        printRef={printRef}
      />

      {legacyChartProps && (
        <PlotLinkModal
          plotLinkModalState={{
            isPlotLinkOpen: isPlotLinkModalOpen,
            onPlotLinkOpen: openPlotLinkModal,
            onPlotLinkClose: closePlotLinkModal,
          }}
          cardConfig={{ header: title }}
          typeAndProps={{
            chartType: legacyChartProps.type,
            chartProps: legacyChartProps.props,
          }}
          data={legacyChartProps.data}
        />
      )}
    </>
  );
};
