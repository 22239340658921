import { useState } from 'react';
import { CompositionDataQuery } from '@revelio/data-access';
import {
  CompanySummaryMetric,
  CompositionMetricValue,
  SelectOption,
} from '../../types';
import { PlotCard } from '../../../../shared/components';
import { StyledPlotSelect } from '../../../../shared/components/plots/styled-plot-select';
import { getBarChartData, getBarMetricFormat } from './get-bar-chart-data';
import { BarChart } from '@revelio/replots';
import { getLegacyBarChartProps } from './get-legacy-props';

const menuOptions = [
  { value: CompanySummaryMetric.Headcount, label: 'Headcount' },
  {
    value: CompanySummaryMetric.AttritionRate,
    label: 'Attrition Rate',
  },
  {
    value: CompanySummaryMetric.GrowthRate,
    label: 'Growth Rate',
  },
  {
    value: CompanySummaryMetric.HiringRate,
    label: 'Hiring Rate',
  },
];

interface CompetitorBarChartsProps {
  compositionData: CompositionDataQuery | undefined;
  primaryRCID: number;
  loading?: boolean;
}

export const CompetitorBarCharts = ({
  compositionData,
  primaryRCID,
  loading,
}: CompetitorBarChartsProps) => {
  const [selectedMetric, setSelectedMetric] = useState<
    SelectOption<CompositionMetricValue>
  >({ value: 'headcount', label: 'Headcount' });

  const legacyChartProps =
    compositionData && primaryRCID && selectedMetric
      ? getLegacyBarChartProps({
          compositionData,
          primaryRCID,
          selectedMetric,
        })
      : undefined;

  const barData =
    compositionData && selectedMetric
      ? getBarChartData({
          data: compositionData,
          metricName: selectedMetric.value,
          selectedEntityId: primaryRCID,
        })
      : [];

  return (
    <PlotCard
      title="Competitors"
      info={
        <div>
          Competitor headcount, attrition rate, growth rate, and hiring rate
        </div>
      }
      topRight={
        <StyledPlotSelect
          value={selectedMetric}
          onChange={(value) => setSelectedMetric(value)}
          options={menuOptions}
        />
      }
      menu={['expand', 'generate-link', 'download-png']}
      legacyChartProps={legacyChartProps}
    >
      {selectedMetric && (
        <BarChart
          data={barData}
          format={getBarMetricFormat(selectedMetric.value)}
          loading={loading}
        />
      )}
    </PlotCard>
  );
};
