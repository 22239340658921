import {
  FeatureFlag,
  PageTitles,
  PrimaryFilters,
  PrimaryView,
} from '@revelio/core';
import {
  SelectionCategories,
  ViewTypes,
  FilterSets,
  PrimaryFilterLimits,
  FilterMenuLimits,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  RICS_AND_COMPANY_FILTERS,
} from '@revelio/filtering';
import Postings, {
  metricModeFilterId,
  providerFilterId,
} from '../../../postings/postings';
import { useFlag } from '@unleash/proxy-client-react';
import { View } from '@revelio/data-access';

const title = [PageTitles.ROLE, PageTitles.JOB_POSTINGS];

const primaryViewFilters = [
  {
    filters: ROLE_GRANULARITY_FILTERS,
    isNested: true,
    limit: 5,
  },
];

const otherFilters = [providerFilterId, metricModeFilterId];

export function RolePostings() {
  const keywordFiltersFeatureFlag = useFlag(FeatureFlag.RolePostingsKeyword);

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    {
      filters: GEOGRAPHY_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    {
      filters: RICS_AND_COMPANY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE_FULL,
    SelectionCategories.RAW_TITLE,
    ...(keywordFiltersFeatureFlag ? [SelectionCategories.KEYWORD] : []),
  ];
  return (
    <Postings
      title={title}
      primaryView={PrimaryView.ROLE}
      viewType={ViewTypes.ROLE}
      primaryFilter={PrimaryFilters.ROLE}
      sharedFilterSetId={FilterSets.ROLE}
      filterSet={FilterSets.ROLE_POSTINGS}
      primaryViewFilters={primaryViewFilters}
      primaryFiltersLimit={PrimaryFilterLimits.POSTINGS}
      selectableFilters={selectableFilters}
      filterMenuLimits={FilterMenuLimits.POSTINGS}
      otherFilters={otherFilters}
      additionalNonActiveFilters={[providerFilterId]}
      isGqlQuery={true}
      isGoRequest={true}
      disableParentSelect={false}
      savedSetView={View.Role}
    />
  );
}

export default RolePostings;
