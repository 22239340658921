import { NodeData } from '../Node';
import { TreeData } from '../useTreeApi';
import { isDirectMatchString } from './search';

type GetSuggestedSortedDataProps = {
  data: TreeData[];
  search: string;
};

type NodeDataSuggested = NodeData & {
  isDirectMatch?: boolean;
};
type TreeDataSuggested = NodeDataSuggested & {
  children?: TreeDataSuggested[];
};

export const getSuggestedSortedData = ({
  data,
  search,
}: GetSuggestedSortedDataProps): TreeDataSuggested[] => {
  const filteredData = data.map((item): TreeDataSuggested => {
    if (item.children) {
      return {
        ...item,
        ...(item.suggestedTitles && {
          isDirectMatch: isDirectMatchString(item.name, search),
        }),
        children: getSuggestedSortedData({ data: item.children, search }),
      };
    } else {
      return {
        ...item,
        isDirectMatch: isDirectMatchString(item.name, search),
      };
    }
  });

  const sortedFilteredData = filteredData.sort((a, b) => {
    if (a.isDirectMatch) return -1;
    if (b.isDirectMatch) return 1;
    return 0;
  });

  return sortedFilteredData;
};
