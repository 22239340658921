import { group, InternMap } from 'd3';
import {
  SelectionListIdNames,
  SelectionListItem,
  SelectionListItems,
} from '../../../engine/filters.model';
import { TreeData } from '../useTreeApi';
import { isArray } from 'lodash';

type LinkedSelectionListItem = SelectionListItem<SelectionListIdNames> & {
  linkedParentIds?: string[];
};

type NestRecursiveProps = {
  selectionListItems: SelectionListItems['value'];
  childMaps: InternMap<
    string | string[] | undefined,
    SelectionListItem<SelectionListIdNames>[]
  >[];
  nestedParentId?: string;
};
const nestRecursive = ({
  selectionListItems,
  childMaps,
  nestedParentId,
}: NestRecursiveProps) =>
  selectionListItems.map((item: LinkedSelectionListItem): TreeData => {
    const nestedId = nestedParentId ? `${nestedParentId}_${item.id}` : item.id;

    const children = childMaps.length > 0 && childMaps[0].get(item.id);

    return {
      id: nestedId,
      name: item.longName || item.id,
      ...(item?.linkedParentIds
        ? {
            linkedIds: item?.linkedParentIds
              ?.filter((id: string) => id !== nestedParentId?.split('_').at(-1))
              .map(
                (id: string) =>
                  `${nestedParentId?.split('_').slice(0, -1).join('_')}_${id}_${item.id}`
              ),
          }
        : {}),
      ...(item.topCleanedTitles && {
        suggestedTitles: item.topCleanedTitles
          .split(',')
          .map((title) => title.trim()),
      }),
      ...(children && {
        children: nestRecursive({
          selectionListItems: children,
          childMaps: childMaps.slice(1),
          nestedParentId: nestedId,
        }),
      }),
    };
  });

/** Nest Selection Lists
 * takes in an array of selection lists and nests them, assuming each list
 * in the array is a child of the previous list */
export const nestSelectionLists = (
  selectionLists: SelectionListItems[]
): TreeData[] => {
  if (selectionLists.length === 0) return [];

  if (selectionLists.length === 1) {
    return selectionLists[0].value.map((item) => ({
      id: item.id,
      name: item.longName || item.id,
    }));
  }

  const groupedLists = selectionLists.slice(1).map((list, i) => {
    /** For case where d[selectionLists[i].id] is an array instead of a single string
     * (i.e. when a selection list item can have multiple parents)
     * duplicate items in the selectionList first */
    const parentListName = selectionLists[i].id;

    const listToGroup = list.value.flatMap((item) => {
      const parentIds = item[parentListName];

      const parentIdArray = isArray(parentIds) ? parentIds : [parentIds];

      return parentIdArray.map((parentId) => ({
        ...item,

        [parentListName]: parentId,

        ...(parentIdArray.length > 1 ? { linkedParentIds: parentIdArray } : {}),
      }));
    });

    return group(listToGroup, (d) => d[parentListName]);
  });

  return nestRecursive({
    selectionListItems: selectionLists[0].value,
    childMaps: groupedLists,
  });
};

export type GetNestedIdProps = {
  selectionLists: SelectionListItems[];
  item: SelectionListItem & { selectionListId: string };
  nestedChildId?: string;
};
export const getNestedId = ({
  selectionLists,
  item,
  nestedChildId,
}: GetNestedIdProps): string => {
  const level = selectionLists.findIndex(
    (list) => list.id === item?.selectionListId
  );
  if (level <= 0) return nestedChildId || `${item.id}`;

  const selectionList = selectionLists[level];
  const selectionListItem = selectionList.value.find(
    (value) => value.id === item.id
  );

  if (!selectionListItem) return `${item.id}`;

  const parentSelectionList = selectionLists[level - 1];
  const parentSelectionListId = parentSelectionList.id;
  const parentId = selectionListItem[parentSelectionListId];

  const parentItem = parentSelectionList.value.find((item) => {
    if (isArray(parentId)) return parentId.includes(item.id);
    return item.id === parentId;
  });

  if (!parentId || !parentItem) return nestedChildId || `${item.id}`;
  const nestedId = (() => {
    if (nestedChildId) return `${parentId}_${nestedChildId}`;
    else return `${parentId}_${item.id}`;
  })();

  return getNestedId({
    selectionLists: selectionLists.slice(0, level),
    item: { selectionListId: parentSelectionListId, id: parentItem.id },
    nestedChildId: nestedId,
  });
};
