import {
  AISearchFilter,
  AISearchPreviousCurrentFilter,
  GetSchoolInfoQuery,
  GetSchoolInfoQueryVariables,
  TalentDiscoveryAiFilterSearchResponse,
} from '@revelio/data-access';
import {
  ValidValueTypes,
  SelectionList,
  SelectionCategories,
  GEOGRAPHY_GRANULARITY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  TreeItem,
  CompanyResultItem,
  GET_SCHOOL_INFO,
  SchoolItem,
  RICS_FILTERS,
} from '@revelio/filtering';
import {
  TalentDiscoveryFilterStates,
  TalentDiscoveryFreeTextState,
  TalentDiscoverySchoolSearchFilterState,
} from '../../td-filter-reducer';
import { isFreeTextFilter, isRangeFilter, isTreeFilter } from '../../types';
import { isEqual } from 'lodash';
import { fetchCompanyMapping } from '../../../../deliverables/company-selection/company-mapping/upload-companies/file-to-company-mapping';
import { Client, OperationResult } from 'urql';
import { processFilterGroup } from './process-filter-group';

export const deserialiseApiToFilterState = async (
  response: TalentDiscoveryAiFilterSearchResponse,
  selectionLists: SelectionList<ValidValueTypes>[],
  gqlClient: Client
): Promise<TalentDiscoveryFilterStates[]> => {
  const { filters } = response;

  const filterState = Object.keys(filters).reduce(
    (deserialisedFilters, key, index) => {
      if (isFreeTextFilter(key)) {
        if (!filters[key]) {
          return deserialisedFilters;
        }

        if (key === 'name') {
          // TODO: probably should check if the name is valid via api search... otherwise add it to unknown filters
          const nameFilterValue = filters[key];
          return [
            ...deserialisedFilters,
            {
              id: Date.now().toString() + index,
              name: key,
              text: [nameFilterValue.name],
            },
          ];
        }

        const keywordsFilters = (filters[key] as AISearchFilter[][])?.reduce(
          (multiKeywords, keywordValue, keywordIndex) => {
            return [
              ...multiKeywords,
              {
                id: Date.now().toString() + index + keywordIndex,
                name: key,
                text: keywordValue.map((keyword) => keyword.name),
              },
            ];
          },
          [] as TalentDiscoveryFreeTextState[]
        );

        return [...deserialisedFilters, ...keywordsFilters];
      }

      if (isTreeFilter(key)) {
        if (filters[key]?.length === 0) {
          return deserialisedFilters;
        }

        const listsWithCurrentNonCurrent = [
          'geography',
          'skill',
          'role',
          'industry',
          'seniority',
          'flight_risk',
          'prestige',
          'remote_suitability',
        ];

        const filterSelectionLists = TDResponseFilterToSelectionListMap[key];
        if (filterSelectionLists) {
          const supportedKey =
            key as keyof typeof TDResponseFilterToSelectionListMap;
          const relevantSelectionLists = selectionLists.filter((list) =>
            filterSelectionLists.includes(list.id as SelectionCategories)
          );

          const filterTreeItems = (() => {
            if (['skill'].includes(key)) {
              return (filters[supportedKey] as AISearchFilter[][]).reduce(
                (skillGroups, skillGroup) => {
                  const deserialisedSkillsFilterGroup = processFilterGroup(
                    skillGroup,
                    relevantSelectionLists
                  );
                  if (!Object.keys(deserialisedSkillsFilterGroup).length) {
                    return skillGroups;
                  }

                  return [...skillGroups, deserialisedSkillsFilterGroup];
                },
                [] as Record<string, TreeItem>[]
              );
            }

            return [
              processFilterGroup(
                filters[supportedKey] as AISearchFilter[],
                relevantSelectionLists
              ),
            ];
          })();

          if (
            !filterTreeItems.filter((item) => Object.keys(item).length).length
          ) {
            return deserialisedFilters;
          }

          const newFilters = filterTreeItems.map(
            (treeItems, treeItemsIndex) => ({
              name: key,
              id: Date.now().toString() + index + treeItemsIndex,
              treeItems: treeItems,
              ...(listsWithCurrentNonCurrent.includes(key)
                ? {
                    isCurrent:
                      response?.filters[key]?.some(
                        (filter) =>
                          (filter as AISearchPreviousCurrentFilter)?.isCurrent
                      ) ?? true,
                  }
                : {}),
            })
          );

          return [...deserialisedFilters, ...newFilters];
        }
      }

      if (isRangeFilter(key)) {
        const filter = filters[key];
        if (!filter?.range?.length || isEqual(filter?.range, [null, null])) {
          return deserialisedFilters;
        }

        const salaryTotalValue = filter?.range as [number, number];
        const minSalary = salaryTotalValue[0];
        const maxSalary = salaryTotalValue[1];

        const salaryRange = (() => {
          if (minSalary === null || minSalary === undefined) {
            return {
              end_value: salaryTotalValue[1],
            };
          }

          if (maxSalary === null || maxSalary === undefined) {
            return {
              start_value: minSalary,
            };
          }

          return {
            start_value: minSalary,
            end_value: maxSalary,
          };
        })();

        return [
          ...deserialisedFilters,
          {
            isCurrent: filter?.isCurrent ?? true,
            name: key,
            ...salaryRange,
          },
        ];
      }

      return deserialisedFilters;
    },
    [] as TalentDiscoveryFilterStates[]
  );

  if (response.filters.company?.length) {
    const csvHeader = 'COMPANY_NAME\n';
    const csvContent = response.filters.company
      .map((company) => company.name)
      .join('\n');
    const csvData = csvHeader + csvContent;

    const file = new File([csvData], 'mappedCompanylist.csv', {
      type: 'text/csv',
    });
    const companyMapping = await fetchCompanyMapping(file);
    const verifiedMappings = companyMapping.filter(
      (company) => company.response !== null
    );

    if (verifiedMappings.length) {
      const companyResultItems = verifiedMappings.reduce(
        (filterResultItems, mappedCompany) => {
          return {
            ...filterResultItems,
            [mappedCompany.response.rcid]:
              mappedCompany.response as CompanyResultItem,
          };
        },
        {}
      );
      filterState.push({
        name: 'company',
        isCurrent:
          response.filters.company.some((company) => company.isCurrent) ?? true,
        companyResultItems,
      });
    }
    // TODO: feedback for any missing mappings
    // const missingMappings = companyMapping.filter(
    //   (company) => company.response === null
    // )
  }

  if (response.filters.school) {
    const schoolSearchResults = await Promise.all(
      response.filters.school.map((school) =>
        fetchSchoolSearchResults(gqlClient, {
          name: school.name,
          page: 1,
        })
      )
    );

    const validSchoolResults = schoolSearchResults
      .filter((result) => result.data?.schoolInfo?.length)
      .map((result) => result.data?.schoolInfo?.[0]);

    const schoolResultItems = validSchoolResults.reduce(
      (acc, school) => {
        if (!school) return acc;
        return {
          ...acc,
          [school.rsid as string]: {
            label: school.name,
            name: school.name,
            rsid: school.rsid,
            primary_name: school.name,
          } as SchoolItem,
        };
      },
      {} as TalentDiscoverySchoolSearchFilterState['schoolResultItems']
    );

    if (Object.keys(schoolResultItems || {}).length > 0) {
      filterState.push({
        name: 'rsid',
        schoolResultItems,
      });
    }
  }

  return filterState;
};

const TDResponseFilterToSelectionListMap: {
  [key in keyof TalentDiscoveryAiFilterSearchResponse['filters']]: (
    | SelectionCategories
    | LocalSelectionCategories
  )[];
} = {
  gender: [SelectionCategories.GENDER],
  ethnicity: [SelectionCategories.ETHNICITY],
  seniority: [SelectionCategories.SENIORITY],
  highest_degree: [SelectionCategories.HIGHEST_DEGREE],
  geography: GEOGRAPHY_GRANULARITY_FILTERS,
  flight_risk: [LocalSelectionCategories.FLIGHT_RISK],
  prestige: [LocalSelectionCategories.PRESTIGE],
  remote_suitability: [LocalSelectionCategories.REMOTE_SUITABILITY],
  role: ROLE_GRANULARITY_FILTERS,
  skill: SKILL_GRANULARITY_FILTERS,
  industry: RICS_FILTERS,
};

const fetchSchoolSearchResults = async (
  gqlClient: Client,
  vars: GetSchoolInfoQueryVariables
): Promise<OperationResult<GetSchoolInfoQuery>> => {
  return gqlClient
    .query<GetSchoolInfoQuery, GetSchoolInfoQueryVariables>(
      GET_SCHOOL_INFO,
      vars
    )
    .toPromise()
    .catch((e) => {
      console.log('error:', e);
      return e;
    });
};
