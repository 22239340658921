import { flatten, maxBy, mean } from 'lodash';

import {
  SelectFilter,
  FilterList,
  FilterItem,
  ValidValueTypes,
  OtherFilterNames,
} from '../filters.model';

import { ricsK50SimiliaryScoreMatrix } from './rics_k50_similiary_score_matrix';
import { ricsK50ToRicsK10Lookup } from './rics_k50_to_rics_k10_lookup';
import { factsetIndustryToRicsK50IdLookup } from './factsetIndustry_to_rics_k50_lookup';
import { SearchParamFilters } from '../filters.deepLinks';
import { parse } from 'query-string';

const getFilterK50Id = (filter: FilterItem<ValidValueTypes>) =>
  filter.ricsK50Id || factsetIndustryToRicsK50IdLookup[filter.id as string];

export const getAdaptiveRoleTaxonomyId = (
  primaryFilters: SelectFilter<FilterList>[]
) => {
  if (primaryFilters.length === 0) {
    return { default: true };
  }

  const K50_SIMILIARITY_THRESHOLD = 0.85;
  const ricsK50List = flatten(
    primaryFilters.map((filter) => filter.value.map(getFilterK50Id))
  );
  const mostFrequentK50Ids = getMostFrequentIds(ricsK50List);

  const mostSimilarK50IdInCollection = mostFrequentK50Ids.map(
    (k50IdCandidate) => {
      return {
        id: k50IdCandidate,
        similarityscore: mean(
          ricsK50List.map(
            (filterK50) =>
              ricsK50SimiliaryScoreMatrix[k50IdCandidate][filterK50]
          )
        ),
      };
    }
  );
  const dominantK50 = maxBy(
    mostSimilarK50IdInCollection,
    (candidate) => candidate.similarityscore
  ) as {
    id: string;
    similarityscore: number;
  };

  if (dominantK50.similarityscore > K50_SIMILIARITY_THRESHOLD) {
    return {
      ricsK50Id: dominantK50.id,
    };
  }

  const ricsK10FallbackList = ricsK50List.map(
    (ricsK50Id) => ricsK50ToRicsK10Lookup[ricsK50Id]
  );
  const mostFrequentK10Id = getMostFrequentIds(ricsK10FallbackList)[0];
  if (
    ricsK10FallbackList.filter((k10) => k10 === mostFrequentK10Id).length >
    ricsK10FallbackList.length / 2
  ) {
    return {
      ricsK10Id: mostFrequentK10Id,
    };
  }

  return {
    default: true,
  };
};

function getMostFrequentIds(ricsK50Ids: string[]): string[] {
  const counts = new Map<string, number>();

  // Count occurrences of each number
  ricsK50Ids.forEach((id) => {
    counts.set(id, (counts.get(id) || 0) + 1);
  });

  // Find the maximum count
  const maxCount = Math.max(...Array.from(counts.values()));
  // console.log("🚀 ~ getMostFrequentIds ~ counts.values():", )

  // Find all numbers with the maximum count
  const mostFrequentIds = Array.from(counts.entries())
    .filter(([num, count]) => count === maxCount)
    .map(([num]) => num);

  return mostFrequentIds;
}

export const getRoleTaxonomyValueFromDeepLink = (
  searchParams: URLSearchParams
) => {
  const deepLinkFilters = parse(searchParams.toString(), {
    arrayFormat: 'comma',
  }) as SearchParamFilters;
  const roleTaxonomyDeepLink = deepLinkFilters[
    OtherFilterNames.ROLE_TAXONOMY
  ] as string;

  if (!roleTaxonomyDeepLink) {
    return null;
  }

  const [roleTaxonomyType, roleTaxonomyId] = roleTaxonomyDeepLink.split('__');
  return {
    [roleTaxonomyType]: roleTaxonomyType === 'default' ? true : roleTaxonomyId,
  };
};
