import { extent, scaleBand, scaleLinear } from 'd3';
import { useRef } from 'react';

import { useResizeObserver } from '@revelio/core';

import { CommonPlotProps, FormatType } from '../../types';
import { PRIMARY_COLOR, getFormatter, useRenderCheck } from '../../utilities';
import { AxisLabel } from '../axis/axis-label';
import { PlotLoader } from '../plot-loader/plot-loader';
import styles from './bar-chart.module.css';
import { BarLabel } from './bar-label';
import { BarTooltip } from './bar-tooltip';

const Y_AXIS_WIDTH = 70;
const BAR_LEFT_PADDING = 10;

const BAR_LABEL_SIZE = 40;
const BAR_LABEL_PADDING = 4;

export type BarData = {
  label: string;
  value: number;
  color?: string;
};

export type BarChartProps = {
  data: BarData[];
  showBarLabels?: boolean;
} & CommonPlotProps;

export const BarChart = ({
  data,
  showBarLabels = true,
  format = FormatType.SI,
  loading = false,
  renderUpdate,
}: BarChartProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { width, height } = useResizeObserver(containerRef);

  const plotWidth =
    width -
    Y_AXIS_WIDTH -
    BAR_LEFT_PADDING -
    (showBarLabels ? BAR_LABEL_SIZE + BAR_LABEL_PADDING : 0);
  const plotHeight = height;

  const isPlotSizeValid = plotWidth > 0 && plotHeight > 0;

  const yScale = scaleBand<string>(
    data.map((d) => d.label),
    [0, plotHeight]
  )
    .paddingOuter(0.1)
    .paddingInner(0.4);

  const [minValue, maxValue] = extent(data.map((d) => d.value));

  const xScale =
    minValue !== undefined && maxValue !== undefined
      ? scaleLinear([Math.min(minValue, 0), maxValue], [0, plotWidth])
      : null;

  const formatValue = getFormatter(format, {
    values: data.map((d) => d.value),
  });
  const tooltipFormatValue = getFormatter(
    format === FormatType.SI ? FormatType.INTEGER : format,
    {
      values: data.map((d) => d.value),
      increasePrecision: 1,
    }
  );

  const chartRef = useRef<SVGGElement>(null);

  useRenderCheck(chartRef, { renderUpdate });

  return (
    <div ref={containerRef} className={styles.container}>
      <svg width="100%" height="100%">
        <g id="y-axis">
          {isPlotSizeValid &&
            yScale.domain().map((domain) => {
              const y = yScale(domain);
              return (
                y && (
                  <AxisLabel
                    key={domain}
                    y={y + yScale.bandwidth() / 2}
                    width={Y_AXIS_WIDTH}
                    label={domain}
                  />
                )
              );
            })}
        </g>
        <g
          id="chart"
          transform={`translate(${Y_AXIS_WIDTH + BAR_LEFT_PADDING}, 0)`}
          ref={chartRef}
        >
          {isPlotSizeValid &&
            xScale &&
            data.map(({ label, value, color }, i) => {
              const x0 = xScale(0);
              const x1 = xScale(value);
              const x = x1 < x0 ? x1 : x0;
              const barWidth = x1 < x0 ? x0 - x1 : x1 - x0;

              const y = yScale(label);
              const maxBarLabelWidth =
                xScale.range()[1] - barWidth + BAR_LABEL_SIZE;

              return (
                barWidth >= 0 &&
                maxBarLabelWidth >= 0 && (
                  <g key={`${label}_${i}`} id={'bar-group'}>
                    <BarTooltip
                      label={
                        <div>
                          <div className={styles.barTooltipLabel}>{label}</div>
                          <div className={styles.barTooltipValue}>
                            {tooltipFormatValue(value)}
                          </div>
                        </div>
                      }
                    >
                      <rect
                        x={x}
                        y={y}
                        width={barWidth}
                        height={yScale.bandwidth()}
                        rx={2}
                        fill={color || PRIMARY_COLOR}
                        className={styles.bar}
                      />
                    </BarTooltip>
                    {showBarLabels && y && (
                      <BarLabel
                        x={Math.max(x1, x0) + BAR_LABEL_PADDING}
                        y={y}
                        width={maxBarLabelWidth}
                        height={yScale.bandwidth()}
                        label={formatValue(value)}
                      />
                    )}
                  </g>
                )
              );
            })}
        </g>
      </svg>
      {loading && <PlotLoader />}
    </div>
  );
};
