import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { ReactNode, useState } from 'react';
import {
  AI_SEARCH_FILTER_OPTION_VALUE,
  TalentDiscoveryFilterOption,
} from './types';
import { SelectFilter } from './select-filter';
import { FilterSelectDropdown } from './filter-select-dropdown';
import { TalentDiscoveryFilter } from './selection-forms/talent-discovery-filter';
import { FILTER_OPTIONS } from './filter-configuration-options';
import {
  NodeModalStateProvider,
  SelectionCategories,
  useRestrictGenderAndEthnicityFilters,
} from '@revelio/filtering';
import { SingleValue } from 'react-select';
import { Option } from '@revelio/filtering';
import { useUnleashFlag } from '../../../hooks/unleash/useUnleashFlag';
import { FeatureFlag } from '@revelio/core';
import { useIsRevelioAdmin } from '@revelio/auth';
import { TDNewTreeFilter } from './tdNewTreeFilter';

interface TalentDiscoveryFilterPopoverProps {
  trigger: ReactNode;
  selectedFilterToEdit?: TalentDiscoveryFilterOption;
  filterId?: string;
  onSubmitSavedFilterSet?: (value: SingleValue<Option> | undefined) => void;
}

export const TalentDiscoveryFilterPopover = ({
  trigger,
  selectedFilterToEdit,
  filterId,
  onSubmitSavedFilterSet,
}: TalentDiscoveryFilterPopoverProps) => {
  const [localSelectedFilter, setSelectedFilter] =
    useState<TalentDiscoveryFilterOption | null>(null);
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);
  const aiSearchFilterFeatureFlag = useUnleashFlag(
    FeatureFlag.TdFiltersAiSearch
  );
  const isRevelioAdmin = useIsRevelioAdmin();

  const selectedFilter = selectedFilterToEdit || localSelectedFilter;

  const handleOpenFilterMenu = () => {
    setIsFilterMenuOpen(true);
  };

  const handleClose = () => {
    setIsFilterMenuOpen(false);

    setTimeout(() => {
      setSelectedFilter(null);
    }, 100); // wait for the popover to close
  };

  const handleCloseFilterMenu = () => {
    //opening nested tree triggers close without this
    if (
      selectedFilter &&
      selectedFilter.value !== SelectionCategories.INDUSTRY
    ) {
      return;
    }

    handleClose();
  };

  const restrictGenderAndEthnicity = useRestrictGenderAndEthnicityFilters();

  const filterOptions = restrictGenderAndEthnicity
    ? FILTER_OPTIONS.filter(
        (option) =>
          ![SelectionCategories.ETHNICITY, SelectionCategories.GENDER].includes(
            option.value as SelectionCategories
          )
      )
    : FILTER_OPTIONS;

  const filterOptionsWithMaybeAiSearch =
    isRevelioAdmin && aiSearchFilterFeatureFlag
      ? filterOptions
      : filterOptions.filter(
          (option) => option.value !== AI_SEARCH_FILTER_OPTION_VALUE
        );

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur
      onClose={handleCloseFilterMenu}
      isOpen={isFilterMenuOpen}
      onOpen={handleOpenFilterMenu}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        minWidth="325px"
        padding={3}
        color="navyBlue.500"
        boxShadow="2xl"
        data-testid={`td-filter-popover-content-${isFilterMenuOpen ? 'open' : 'closed'}`}
        width="fit-content"
      >
        {(() => {
          if (!isFilterMenuOpen) {
            return null;
          }

          if (!selectedFilter) {
            return (
              <SelectFilter
                filterOptions={filterOptionsWithMaybeAiSearch}
                setSelectedFilter={setSelectedFilter}
                closeMenu={handleCloseFilterMenu}
              />
            );
          }

          if (selectedFilter) {
            return (
              <div data-testid="filter-menu-popover">
                <PopoverHeader
                  marginBottom={2}
                  padding={0}
                  fontWeight="semibold"
                  borderBottomWidth={0}
                >
                  {!selectedFilterToEdit && (
                    <FilterSelectDropdown
                      filterOptions={filterOptionsWithMaybeAiSearch}
                      selectedFilter={selectedFilter}
                      onChangeSelectedFilter={setSelectedFilter}
                    />
                  )}
                </PopoverHeader>
                {/* provider needed here to stop closing the popover when renaming a saved filter set */}
                <NodeModalStateProvider>
                  {selectedFilter.value === SelectionCategories.INDUSTRY ? (
                    <TDNewTreeFilter
                      selectionListIds={
                        selectedFilter.filterGranularities as SelectionCategories[]
                      }
                      onClose={handleClose}
                      selectedFilter={selectedFilter}
                      filterId={filterId}
                    />
                  ) : (
                    <TalentDiscoveryFilter
                      selectedFilter={selectedFilter}
                      closeMenu={handleClose}
                      filterId={filterId}
                      onSubmitSavedFilterSet={onSubmitSavedFilterSet}
                    />
                  )}
                </NodeModalStateProvider>
              </div>
            );
          }

          return null;
        })()}
      </PopoverContent>
    </Popover>
  );
};
