import LZString from 'lz-string';
import {
  Textarea,
  IconButton,
  Button,
  ButtonGroup,
  Flex,
} from '@chakra-ui/react';
import { getAuthDashMetaCsrfToken } from '@revelio/auth';
import { useState } from 'react';
import {
  aiFilterSearchFeedbackRequest,
  aiFilterSearchRequest,
} from './ai-filter-search-api-request';
import { deserialiseApiToFilterState } from './deserialise-api-response';
import {
  ALL_SUPPORTED_FILTERS,
  LocalSelectionCategories,
  useSelectionLists,
} from '@revelio/filtering';
import { loadAllTalentDiscoveryStateFromUrl } from '../../../useSyncTalentDiscoveryFIltersWithUrl';
import { useLocation } from 'react-router';
import { useTalentDiscoveryFilter } from '../../td-filter-provider';
import { noop } from 'lodash';
import { useClient } from 'urql';
import { FiThumbsUp, FiThumbsDown } from 'react-icons/fi';

export const AiFilterSearch = ({ closeMenu }: { closeMenu: () => void }) => {
  const { aiSearchState, setAiSearchState } = useTalentDiscoveryFilter();
  const csrfToken = getAuthDashMetaCsrfToken();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { dispatch } = useTalentDiscoveryFilter();
  const gqlClient = useClient();

  const selectionLists = useSelectionLists([
    ...ALL_SUPPORTED_FILTERS,
    LocalSelectionCategories.FLIGHT_RISK,
    LocalSelectionCategories.PRESTIGE,
    LocalSelectionCategories.REMOTE_SUITABILITY,
  ]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const { response, uuid } = await aiFilterSearchRequest({
        prompt: aiSearchState.prompt,
        csrfToken: csrfToken || '',
      });

      const talentDiscoveryFilters = await deserialiseApiToFilterState(
        response,
        selectionLists,
        gqlClient
      );
      const stringifiedFilterState = JSON.stringify(talentDiscoveryFilters);

      const deeplink = `?filters=${LZString.compressToEncodedURIComponent(
        stringifiedFilterState
      )}`;

      if (deeplink !== location.search) {
        loadAllTalentDiscoveryStateFromUrl(
          dispatch,
          noop, // TODO: currently no support for columns in ai filter search
          new URLSearchParams(deeplink)
        );
      }
      setAiSearchState((prevState) => ({
        ...prevState,
        uuid,
        isCorrect: null,
      }));
      closeMenu();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleIsAiSearchCorrect = ({ isCorrect }: { isCorrect: boolean }) => {
    if (isCorrect === aiSearchState.isCorrect) {
      return;
    }

    aiFilterSearchFeedbackRequest({
      uuid: aiSearchState.uuid,
      isCorrect,
      csrfToken: csrfToken || '',
    });
    setAiSearchState((prevState) => ({
      ...prevState,
      isCorrect,
    }));
  };

  return (
    <>
      <Textarea
        value={aiSearchState.prompt}
        onChange={(e) => {
          setAiSearchState((prevState) => ({
            ...prevState,
            prompt: e.target.value,
            uuid: '',
          }));
        }}
        placeholder="e.g. 'Find me women in NY that work at Google from the top 5 universities'"
        height="65px"
        fontSize="12px"
        flex={1}
        padding="6px"
        colorScheme="green"
        width="475px"
        resize="none"
      />
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          {aiSearchState.uuid && (
            <>
              <IconButton
                aria-label="Thumbs up"
                icon={<FiThumbsUp />}
                onClick={() =>
                  handleIsAiSearchCorrect({
                    isCorrect: true,
                  })
                }
                size="sm"
                variant="ghost"
                colorScheme={
                  aiSearchState.isCorrect === true ? 'green' : 'gray'
                }
              />
              <IconButton
                aria-label="Thumbs down"
                icon={<FiThumbsDown />}
                onClick={() =>
                  handleIsAiSearchCorrect({
                    isCorrect: false,
                  })
                }
                size="sm"
                variant="ghost"
                colorScheme={aiSearchState.isCorrect === false ? 'red' : 'gray'}
              />
            </>
          )}
        </Flex>
        <ButtonGroup spacing={4}>
          <Button
            variant="link"
            size="sm"
            fontSize="xs"
            fontWeight={600}
            colorScheme="gray"
            onClick={closeMenu}
          >
            Cancel
          </Button>
          <Button
            data-testid="filter-popover-submit"
            isDisabled={!aiSearchState.prompt}
            colorScheme="green"
            variant="solid"
            size="sm"
            fontSize="xs"
            onClick={handleSubmit}
            isLoading={loading}
          >
            Search
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};
