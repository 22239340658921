import {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
  useState,
  SetStateAction,
} from 'react';
import {
  initialTalentDiscoveryFilterState,
  TalentDiscoveryFilterAction,
  talentDiscoveryFilterReducer,
  TalentDiscoveryFilterState,
} from './td-filter-reducer';

const TalentDiscoveryFilterContext = createContext<{
  state: TalentDiscoveryFilterState;
  dispatch: Dispatch<TalentDiscoveryFilterAction>;
  aiSearchState: AiSearchState;
  setAiSearchState: Dispatch<SetStateAction<AiSearchState>>;
}>({
  state: initialTalentDiscoveryFilterState,
  dispatch: () => undefined,
  aiSearchState: {
    prompt: '',
    uuid: '',
    isCorrect: null,
  },
  setAiSearchState: () => undefined,
});

type AiSearchState = {
  prompt: string;
  uuid: string;
  isCorrect: boolean | null;
};
export const TalentDiscoveryFilterProvider = ({
  children,
}: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    talentDiscoveryFilterReducer,
    initialTalentDiscoveryFilterState
  );
  const [aiSearchState, setAiSearchState] = useState<AiSearchState>({
    prompt: '',
    uuid: '',
    isCorrect: null,
  });

  return (
    <TalentDiscoveryFilterContext.Provider
      value={{ state, dispatch, aiSearchState, setAiSearchState }}
    >
      {children}
    </TalentDiscoveryFilterContext.Provider>
  );
};

export const useTalentDiscoveryFilter = () =>
  useContext(TalentDiscoveryFilterContext);
